import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


Vue.use(VueRouter)

// 清除重复点击菜单的错误提示
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 配置页面加载进度条
NProgress.configure({ showSpinner: false })
const Landing = () => import('../views/landing')
const Admin = () => import('../views/admin')
const Login = () => import('../views/Login')
const CompanyRegister = () => import('../views/companyRegister')
const inviteUser = () => import('../views/emailViews/inviteUser.vue')
const inviteShip = () => import('../views/emailViews/inviteShip.vue')
const orderOffline = () => import('../views/businessManager/orderOffline')
const ClientApp = () => import('../views/clientApp')
const Page404 = () => import('../views/404')

// 初始的路由
export const initialRoutes = [
  {
    path: '/',
    redirect: { name: 'login' },
    name: 'index'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/company_register',
    name: 'companyRegister',
    component: CompanyRegister
  },
  {
    path: '/user_register',
    name: 'inviteUser',
    component: inviteUser
  },
  {
    path: '/ship_register',
    name: 'inviteShip',
    component: inviteShip
  },
  {
    path: '/order_offline',
    name: 'orderOffline',
    component: orderOffline
  },
  {
    path: '/client-apps',
    name: 'clientApp',
    component: ClientApp
  },
  {
    path: '/404',
    name: 'page404',
    component: Page404
  }
]

export const router = new VueRouter({
  routes: initialRoutes
})

/**
 * 添加导航标签
 * @param {Object} route:跳转至的路由对象
 */
const addNavigationTags = (route) => {
  const navigationTags = [...store.state.navigationTags]
  const index = store.state.navigationTags.findIndex(item => item.fullPath === route.fullPath)
  if (index === -1) {
    navigationTags.push({
      name: route.meta.menuName,
      fullPath: route.fullPath
    })
  } else {
    store.commit('setCurrentTag', store.state.navigationTags[index])
  }
  store.commit('setNavigationTags', navigationTags)
}

/**
 * 初次登录或页面刷新后添加动态路由
 */
export const addDynamicRoutes = () => {
  const adminRoute = {
    path: '/admin',
    name: 'admin',
    component: Admin,
    children: [
      {
        path: '/admin/welcome',
        name: 'Welcome',
        component: Landing
      }
    ]
  }
  // 设置404页面
  const route404 = {
    name: 'notFound',
    path: '*',
    redirect: '/404'
  }
  // 设置admin下的子路由
  const getRouteItem = (menuItem) => {
    /**
     * 通过后端返回的数据设置路由
     */
    if (menuItem.children?.length) {
      // 如果children有元素，则是父级目录，不需要路由
      menuItem.children.forEach(item => {
        getRouteItem(item)
      })
    } else {
      adminRoute.children.push({
        name: menuItem.name,
        path: menuItem.router || 'error_404',
        meta: {
          menuName: menuItem.name,
          functions: menuItem.functions || [],
        },
        component: () => import('../views/' + menuItem.component)
      })
      // TODO: remove hardcode
      if (menuItem.name === 'MENU_ACCOUNTS_RECEIVABLE_INVOICE') {
        const result = adminRoute.children.find(({ name }) => name === 'MENU_ACCOUNTS_RECEIVABLE_INVOICE')
        result.props = true
      }
    }
  }
  store.state.menus.forEach(item => {
    getRouteItem(item)
  })

  // 创建新的routes,新routes中必须包含旧的routes
  const dynamicRoutes = [...initialRoutes, adminRoute, route404]
  // 替换掉原来路由对象的routes
  router.options.routes = dynamicRoutes
  // 创建一个新的路由对象
  const newRouter = new VueRouter({
    // 新路由对象的routes必须保持与新的routes保持一致
    routes: dynamicRoutes
  })
  // 把新路由对象的matcher（匹配器）赋值给老的路由对象的matcher,让vue能够真正更新路由
  router.matcher = newRouter.matcher
}

/**
 * 重置路由数据
 */
export const resetRouter = () => {
  router.options.routes = initialRoutes
  const newRouter = new VueRouter({
    routes: initialRoutes
  })
  router.matcher = newRouter.matcher
}

/**
 * 判断是否有动态路由
 */
const judgeHasDynamicRoutes = () => {
  const { routes } = router.options
  return !!routes.find(item => item.name === 'admin')
}

// 路由白名单,白名单的路由在路由拦截中直接通过
const WHITE_LIST = ['index', 'login', 'companyRegister', 'inviteUser', 'inviteShip', 'orderOffline', 'clientApp']

/**
 * 拦截路由，添加导航标签
 */
router.beforeEach((to, from, next) => {
  // 页面加载进度条
  NProgress.start()

  // 白名单内的路由直接通过
  if (WHITE_LIST.includes(to.name)) {
    return next()
  }

  // 没有token,跳转到登录页面
  if (!store.state.token) {
    return next({
      name: 'login',
      replace: true
    })
  }

  // 没有动态路由则添加动态路由
  if (!judgeHasDynamicRoutes()) {
    addDynamicRoutes()
    router.push({
      path: to.path
    })
  }

  // 添加顶部导航标签
  if (!['admin', 'page404'].includes(to.name)) {
    addNavigationTags(to)
  }
  next()
})

router.afterEach(() => {
  // 页面加载进度条
  NProgress.done()
})
