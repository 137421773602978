<template>
  <div
    class="company-item"
    :class="{active:active}">
    <span>{{ company.companyName }}</span>
    <span class="font-small">{{ getCompanyStatusText(company) }}</span>
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'

export default {
  name: 'CompanyItem',
  mixins: [baseMixin],
  props: {
    company: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.company-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px 3px;
  padding: 10px;
  border-radius: 10px;
  color: #909399;
  cursor: pointer;
  transition: background-color .1s;
  box-shadow: 0 0 5px 0 rgba(136, 152, 170, .15);

  &:hover:not(.active) {
    background-color: #F2F6FC50;
  }

  > span:first-child {
    @include single-row-ellipsis;
    display: block;
    width: 90%;
    margin-bottom: 10px;
  }

  > span:last-child {
    display: block;
    width: 100%;
    text-align: right;
  }

  &.active {
    color: white;
    background-color: $primary-color;
  }
}
</style>
