<template>
  <el-popover
    v-model="visible"
    placement="bottom-start"
    :title="$t('icon')"
    width="600"
    trigger="click">
    <div class="icon-list">
      <template v-for="iconItem in icons">
        <em
          :class="iconItem"
          :key="iconItem"
          @click="selectIcon(iconItem)">
        </em>
      </template>
    </div>
    <el-input
      slot="reference"
      :value="value"
      :suffix-icon="value">
    </el-input>
  </el-popover>
</template>

<script>
import icons from './icons'

export default {
  name: 'index',
  props: {
    value: {
      type: String
    }
  },
  data () {
    return {
      icons,
      icon: '',
      suffixIcon: '',
      visible: false
    }
  },
  methods: {
    selectIcon (iconItem) {
      this.$emit('input', iconItem)
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-list {
    display: flex;
    flex-wrap: wrap;
    height: 400px;
    overflow-y: auto;

    i {
      display: inline-block;
      margin: 5px;
      padding: 10px;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #EBEEF5;
      }
    }
  }
</style>
