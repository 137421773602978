import { router } from '@/router'
import Loading from './loading/index.vue'

let instance = null
export default {
  install (Vue, options) {
    Vue.prototype.hasPermission = (code) => {
      return router.currentRoute.meta.functions.find(item => item.code === code)
    }
    if (!instance) {
      const MyLoading = Vue.extend(Loading)
      instance = new MyLoading().$mount(document.createElement('div'))
      document.body.appendChild(instance.$el)
    }
    Vue.prototype.$oneLoading = {
      show (text = 'Saving...') {
        instance.text = text
        instance.show = true
      },
      hide () {
        instance.show = false
      }
    }
  }
}
