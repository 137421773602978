<template>
  <div>
    <el-divider class="el-divider-border" ></el-divider>
    <div
      class="company-item-container"
      :class="{active:active}">
      <div class="company-item-baseInfo">
        <div v-if="!isHideLogo">
          <img
          v-if="logoSrc"
          height="35px"
          :src="logoSrc"
          alt=""
          />
          <div v-else >
            <el-avatar :size="35">{{getAvatarName(company.companyName)}}</el-avatar>
          </div>
        </div>
        <div class="companyName">
          <p>{{ company.companyName }}</p>
        </div>
      </div>
      <div>
          <el-tag v-if="tagText" :type="tagType">{{tagText}}</el-tag>
      </div>
    </div>
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'

export default {
  name: 'CompanyItemNew',
  mixins: [baseMixin],
  props: {
    company: {
      type: Object,
      required: true
    },
    currentCompany: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    isHideLogo: {
      type: Boolean,
      default: false
    },
    showStatusTage: {
      type: Boolean,
      default: false
    },
    logoSrc: {
      type: String
    }
  },
  data () {
    return {
      tagType: null,
      tagText: null
    }
  },
  mounted () {
    this.setInitData()
  },
  methods: {
    setInitData () {
      if (this.$props.company.id !== 'noCompany') {
        if (this.showStatusTage) {
          this.tagText = this.getCompanyStatusText(this.$props.company)
          this.tagType = this.getTagType(this.tagText)
        } else {
          /* can added other tags here */
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.company-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  transition: background-color .1s;
  color: $primary-color-font;
  &:hover:not(.active) {
    background-color: #F2F6FC50;

  }

  &.active {
    // color: $primary-color;
    font-weight: 500;
    background-color:#fff;
    box-shadow: 0 0 5px 5px  #eaeaea;
  }
  .companyName{
    padding-left: 20px;
  }
  .company-item-baseInfo{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    img {
      height: 35px;
      width: 35px;
      border-radius: 35px;
    }
  }
}
</style>
