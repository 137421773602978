<template>
  <el-dialog
    :visible.sync="dialogVisible"
    custom-class="file-preview"
    top="5vh"
    width="910px"
    :show-close="false"
    :close-on-click-modal="false"
    @close="$emit('update:visible', false)"
    v-on="$listeners">
    <div class="file-container">
      <div class="btn">
        <el-button
          type="primary"
          size="small"
          round
          @click="print">
          {{ $t('print') }}
        </el-button>
        <el-button
          type="success"
          size="small"
          round
          @click="download">
          {{ $t('download') }}
        </el-button>
        <el-button
          type="danger"
          size="small"
          round
          @click="$emit('update:visible', false)">
          {{ $t('close') }}
        </el-button>
      </div>
      <iframe
        ref="iframe"
        v-if="src"
        :src="src"
        frameborder="0"
        @load="handleLoad"
        @error="handlerError">
      </iframe>
      <div
        v-if="loading"
        class="loading">
        <em class="el-icon-loading"></em>
        <span>Loading...</span>
      </div>
      <div
        v-if="error"
        class="error">
        <em class="el-icon-error"></em>
        <span>Loading failed</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import baseMixin from '@/mixins/base-mixin.js'
import { getFileBlobUrl } from '@/services/modules/comm.js'

export default {
  name: 'index',
  mixins: [baseMixin],
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    // 后端返回的文件对象数据
    file: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      dialogVisible: this.visible,
      src: '',
      loading: true,
      error: false,
      iframe: null
    }
  },
  watch: {
    visible () {
      this.dialogVisible = this.visible
      if (this.visible) {
        this.getFile()
      }
      else {
        URL.revokeObjectURL(this.src)
      }
    }
  },
  mounted () {
  },
  methods: {
    getFileBlobUrl,
    getFile () {

      let fileId
      if (this.file.sysFileId) {
        fileId = this.file.sysFileId
      } else {
        fileId = this.file.id
      }

      this.getFileBlobUrl(fileId).then(url => {
        this.src = url
      }).catch(e => {
        this.loading = false
        this.error = true
      })
    },
    handleLoad () {
      this.loading = false
    },
    handlerError () {
      this.error = true
    },
    print () {
      const filename = this.file.originalName || this.file.name
      if (!filename.includes('.pdf')) {
        return this.$message.error(this.$t('notSupportedPrint'))
      }
      this.downloadFileMixin(this.file).then(url => {
        this.iframe = document.createElement('iframe')
        this.iframe.style.display = 'none'
        this.iframe.src = url
        document.body.appendChild(this.iframe)
        this.iframe.onload = () => {
          this.iframe.contentWindow.print()
        }
      })
    },
    download () {
      this.downloadFileMixin(this.file).then(url => {
        const a = document.createElement('a')
        a.href = url
        a.download = this.file.originalName || this.file.name
        a.click()
      })
    }
  },
  beforeDestroy () {
    this.iframe && document.body.removeChild(this.iframe)
  }
}
</script>

<style lang="scss" scoped>

/deep/ .el-dialog__body {
  padding-top: 0;
}

.file-preview {
  .file-container {
    position: relative;
    height: 84vh;

    .btn {
      margin-bottom: 20px;
      text-align: right;
    }

    iframe {
      width: 867px;
      height: calc(100% - 40px);
    }

    .loading,
    .error {
      position: absolute;
      top: 50%;
      left: 50%;
      @include flex-center-column;
      transform: translate(-50%, -50%);

      i {
        font-size: 30px;
      }
    }

    .error {
      font-size: 50px;
      color: #F56C6C;
    }
  }
}
</style>
