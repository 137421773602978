<template>
  <div
    v-if="show"
    class="loading-container">
    <div class="center-block">
      <base-loading></base-loading>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
import BaseLoading from '@/components/BaseLoading'

export default {
  name: 'Loading',
  components: { BaseLoading },
  data () {
    return {
      text: '加载中...',
      show: false
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #61616190;
  z-index: 99999;

  .center-block {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    width: 260px;
    height: 160px;
    padding: 20px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 0 2px 0 #757575;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: #909399;
  }
}
</style>
