import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/icons/iconfont.css'
import App from './App.vue'
import { router } from './router'
import store from './store'
import i18n from './i18n'
import _ from 'lodash'
import moment from 'moment'
import directives from '@/directives'
import filters from '@/filters'
import request from '@/services/request'
import apis from '@/services/apis'
import { roleCode, permissionFunctionCode, orderStatus, orderStatusDesc, orderStatusNum } from '@/config'
import BaseButtonDelete from '@/components/BaseButtonDelete'
import BaseFormAddEdit from '@/components/BaseFormAddOrEdit'
import BaseTitle from '@/components/BaseTitle'
import CommonQuery from '@/components/CommonQuery'
import PhoneInput from '@/components/PhoneInput'
import BaseBlockTitle from '@/components/BaseBlockTitle'
import BaseNoData from '@/components/BaseNoData'
import CompanyItem from '@/components/CompanyItem'
import CompanyItemNew from '@/components/CompanyItem/CompanyItemNew'
import FilePreview from '@/components/FilePreview'
import BaseBusinessForm from '@/components/BaseBusinessForm'
import dataV from '@jiaminghi/data-view'
import plugins from './plugins'
import JsonViewer from 'vue-json-viewer'

Vue.config.productionTip = false
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
  size: 'small'
})
Vue.use(JsonViewer)
Vue.use(dataV)
Vue.use(plugins)

// 设置本地时间
moment.locale('zh-cn')

// 挂在原型对象
Vue.prototype.$request = request
Vue.prototype.$apis = apis
Vue.prototype.$_ = _
Vue.prototype.$moment = moment
Vue.prototype.$roleCode = roleCode
Vue.prototype.$permissionFunctionCode = permissionFunctionCode
Vue.prototype.$orderStatus = orderStatus
Vue.prototype.$orderStatusDesc = orderStatusDesc
Vue.prototype.$orderStatusNum = orderStatusNum
Vue.prototype.$eventBus = new Vue()

// 注册全局组件
Vue.component('base-title', BaseTitle)
Vue.component('base-button-delete', BaseButtonDelete)
Vue.component('base-form-add-or-edit', BaseFormAddEdit)
Vue.component('base-block-title', BaseBlockTitle)
Vue.component('base-no-data', BaseNoData)
Vue.component('common-query', CommonQuery)
Vue.component('phone-input', PhoneInput)
Vue.component('company-item', CompanyItem)
Vue.component('company-item-new', CompanyItemNew)
Vue.component('file-preview', FilePreview)
Vue.component('base-business-form', BaseBusinessForm)

// 注册自定义指令
Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key])
})

// 注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
