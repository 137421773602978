<template>
  <span class="title">
    <em
      :style="{color:$store.state.currentTheme.primaryColor}"
      class="iconfont"
      :class="fontClass">
    </em>
    <span
      :style="{fontSize:fontSize,fontWeight:bold}"
      class="text">
      {{ text }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'index',
  props: {
    text: {
      type: String,
      required: true
    },
    // 圆点大小：值为'large'、'normal'、'small'
    size: {
      type: String,
      default: 'large'
    },
    fontSize: {
      type: String,
      default: '16px'
    },
    bold: {
      type: String,
      default: 'bold'
    }
  },
  computed: {
    fontClass () {
      switch (this.size) {
        case 'normal':
          return 'icon-yuandian'
        case 'small':
          return 'icon-yuandianxiao'
        default:
          return 'icon-yuandianzhong'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  color: $primary-color;
}
</style>
