<template>
  <div class="business-form-container">
    <div class="field-form">
      <el-form
        ref="form"
        size="mini"
        :model="formData"
        :show-message="false"
        :disabled="disabled"
        label-position="top"
        :label-width="labelWidth">
        <template v-for="category in fields">
          <el-row
            :gutter="20"
            class="el-row-category"
            :key="category.categoryName">
            <div class="category-name">{{ $t(category.categoryName) }}</div>
            <template v-for="(item,index) in category.fields">
              <el-col
                v-if="item.fieldType!=='invisible'"
                :key="item.fieldName+index"
                :span="item.colSpan || span">
                <el-form-item
                  v-if="item.fieldType!=='table'"
                  :label="$t(item.label || item.fieldName)"
                  :prop="item.fieldName"
                  :label-width="item.formConfig?item.formConfig.labelWidth?item.formConfig.labelWidth:labelWidth:labelWidth"
                  :rules="getRules(item)">
                  <!--普通输入框-->
                  <el-input
                    v-if="!item.formConfig || !item.formConfig.type || item.formConfig.type === 'input'"
                    v-model="formData[item.fieldName]"
                    size="mini"
                    :disabled="!!item.disabled"
                    @input="onInput($event,item)">
                    <template
                      v-if="item.formConfig && item.formConfig.prepend"
                      slot="prepend">{{ item.formConfig.prepend }}
                    </template>
                    <template
                      v-if="item.formConfig && item.formConfig.append"
                      slot="append">{{ item.formConfig.append }}
                    </template>
                  </el-input>
                  <!--US$ 金额输入框-->
                  <el-input
                    v-else-if="item.formConfig.type === 'usd'"
                    v-model="formData[item.fieldName]"
                    class="usd-input"
                    size="mini"
                    :disabled="!!item.disabled"
                    @input="onInput($event,item)">
                    <span slot="prefix" class="usd-prefix"
                    >US $</span>
                  </el-input>
                  <!--文本域输入框-->
                  <el-input
                    v-else-if="item.formConfig.type === 'textarea'"
                    type="textarea"
                    :rows="3"
                    v-model="formData[item.fieldName]"
                    :disabled="!!item.disabled"
                    @input="onInput($event,item)">
                  </el-input>
                  <!--数字输入框-->
                  <el-input-number
                    v-else-if="item.formConfig.type === 'inputNumber'"
                    v-model="formData[item.fieldName]"
                    size="mini"
                    :min="item.formConfig.min || -Infinity"
                    :max="item.formConfig.max || Infinity"
                    :disabled="!!item.disabled"
                    @input="onInput($event,item)">
                  </el-input-number>
                  <!--单选组-->
                  <el-radio-group
                    style="margin-left: 20px"
                    v-else-if="item.formConfig.type === 'radio'"
                    v-model="formData[item.fieldName]"
                    :disabled="!!item.disabled">
                    <el-radio
                      v-for="(option,index) in item.formConfig.options"
                      :key="index"
                      :label="option.value">
                      {{ $t(option.label || option.value) }}
                    </el-radio>
                  </el-radio-group>
                  <!--多选组-->
                  <el-checkbox-group
                    style="margin-left: 20px"
                    v-else-if="item.formConfig.type === 'checkbox'"
                    v-model="formData[item.fieldName]"
                    :disabled="!!item.disabled">
                    <el-checkbox
                      v-for="(option,index) in item.formConfig.options"
                      :key="index"
                      :label="option.value">
                      {{ $t(option.label || option.value) }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <!--下拉选择框-->
                  <el-select
                    style="width: 100%"
                    v-else-if="item.formConfig.type === 'select'"
                    v-model="formData[item.fieldName]"
                    size="mini"
                    filterable
                    :disabled="!!item.disabled"
                    @change="onSelectChange($event,item)">
                    <el-option
                      v-for="option in item.formConfig.options"
                      :key="option.value"
                      :label="option.label || option.value"
                      :value="option.value">
                    </el-option>
                  </el-select>
                  <!--级联选择器-->
                  <el-cascader
                    style="width: 100%"
                    v-else-if="item.formConfig.type === 'cascader'"
                    v-model="formData[item.fieldName]"
                    size="mini"
                    :options="item.formConfig.options"
                    :show-all-levels="item.formConfig.showAllLevels===undefined?true:item.formConfig.showAllLevels"
                    :props="item.formConfig.props"
                    :disabled="!!item.disabled">
                  </el-cascader>
                  <!--开关-->
                  <el-switch
                    style="margin-left: 10px"
                    v-else-if="item.formConfig.type === 'switch'"
                    v-model="formData[item.fieldName]"
                    :active-text="item.formConfig.activeText || ''"
                    :inactive-text="item.formConfig.inactiveText || ''"
                    :disabled="!!item.disabled">
                  </el-switch>
                  <!--日期选择器-->
                  <el-date-picker
                    style="width: 100%"
                    v-else-if="['date','datetime'].includes(item.formConfig.type)"
                    v-model="formData[item.fieldName]"
                    size="mini"
                    :type="item.formConfig.type"
                    value-format="yyyy-MM-dd 12:00:00"
                    :disabled="!!item.disabled">
                  </el-date-picker>
                  <!--时间选择器-->
                  <el-time-picker
                    style="width: 100%"
                    v-else-if="item.formConfig.type === 'time'"
                    v-model="formData[item.fieldName]"
                    size="mini"
                    value-format="HH:mm:ss"
                    :disabled="!!item.disabled">
                  </el-time-picker>
                  <!--选择表格输入-->
                  <el-input
                    v-else-if="item.formConfig.type === 'select-table'"
                    v-model="formData[item.fieldName]"
                    size="mini"
                    :disabled="!!item.disabled"
                    :placeholder="$t('pleaseSelect')"
                    @focus="item.handler($event,$vnode.context)">
                  </el-input>
                </el-form-item>
                <!--表格-->
                <div
                  v-if="item.fieldType==='table'"
                  class="auto-table">
                  <div
                    class="title">
                    <span>{{ $t(item.label || item.fieldName) }}</span>
                    <span v-show="!(disabled || item.disabled)">
                      <em
                        class="el-icon-circle-plus-outline"
                        @click="addTableRow(formData[item.fieldName],item)">
                      </em>
                      <em
                        class="el-icon-remove-outline"
                        @click="deleteTableRow(formData[item.fieldName],item.fieldName)">
                      </em>
                    </span>
                  </div>
                  <el-table
                    border
                    :ref="item.fieldName"
                    size="small"
                    :data="formData[item.fieldName]"
                    :show-summary="!!item.summaryFields"
                    :summary-method="getSummaries(item.summaryFields)"
                    style="width: 100%"
                    @row-click="onRowClick($event,item)">
                    <el-table-column
                      v-if="!disabled"
                      type="selection"
                      align="center"
                      width="55">
                    </el-table-column>
                    <el-table-column
                      v-for="field in item.fields"
                      :key="field.fieldName"
                      :width="field.width"
                      align="center"
                      :prop="field.fieldName"
                      :label="$t(field.label || field.fieldName)">
                      <template slot-scope="scope">
                        <el-form-item
                          label-width="0"
                          :prop="`${item.fieldName}.${scope.$index}.${field.fieldName}`"
                          :rules="getRules(field)">
                          <el-input
                            v-if="!field.formConfig || !field.formConfig.type || field.formConfig.type === 'input'"
                            v-model="scope.row[field.fieldName]"
                            size="mini"
                            type="text"
                            :disabled="!!field.disabled"
                            @input="onInput($event,field)">
                            <template
                              v-if="field.formConfig && field.formConfig.prepend"
                              slot="prepend">{{ field.formConfig.prepend }}
                            </template>
                            <template
                              v-if="field.formConfig && field.formConfig.append"
                              slot="append">{{ field.formConfig.append }}
                            </template>
                          </el-input>
                          <!--US$ 金额输入框-->
                          <el-input
                            v-else-if="field.formConfig.type === 'usd'"
                            v-model="scope.row[field.fieldName]"
                            class="usd-input"
                            size="mini"
                            :disabled="!!field.disabled"
                            @input="onInput($event,field)">
                            <span slot="prefix" class="usd-prefix"
                            >US $</span>
                          </el-input>
                          <!--日期选择器-->
                          <el-date-picker
                            v-else-if="['date','datetime'].includes(field.formConfig.type)"
                            style="width: 100%"
                            v-model="scope.row[field.fieldName]"
                            size="mini"
                            :type="field.formConfig.type"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :disabled="!!field.disabled">
                          </el-date-picker>
                          <!--时间选择器-->
                          <el-time-picker
                            v-else-if="field.formConfig.type === 'time'"
                            style="width: 100%"
                            v-model="scope.row[field.fieldName]"
                            size="mini"
                            value-format="HH:mm:ss"
                            :disabled="!!field.disabled">
                          </el-time-picker>
                          <!--下拉框-->
                          <el-select
                            filterable
                            v-else-if="field.formConfig.type === 'select'"
                            style="width: 100%"
                            v-model="scope.row[field.fieldName]"
                            size="mini"
                            :disabled="!!field.disabled"
                            @change="onSelectChange($event,field)">
                            <el-option
                              v-for="option in field.formConfig.options"
                              :key="option.value"
                              :label="option.label"
                              :value="option.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </template>
          </el-row>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseBusinessForm',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * fields说明
     * {
     *    fieldName: {String} 字段名称，必须
          fieldType: {Boolean} 字段类型，包括基本数据类型及['invisible','table']
          default: {Any} 默认值
          label: {String} form标签，若没配置，则取fieldName
          rules: {Array} 表单校验规则，若没配置，默认为必须，若非必填，可设置为空数组
          colSpan: {Number} el-col的span值
          gangedEvent: {String} 关联事件名，如果此字段有值，选择或点击后会抛出事件用于父组件监听
          disabled: {Boolean} 当前字段是否禁用编辑
          fields: {Array} 表格类型字段的字段列表，当fieldType为'table'时可用
          handler: {Function} 表格输入框的执行函数
          formConfig: {Object} 表单配置项
            {
              type:  {String} 表单类型，包括element-ui基本表单类型，默认为input输入框
              labelWidth: {String} 单个form-item的label的宽度，如果没设置则继承form的labelWidth
              disabled: {Boolean} 当前字段是否禁用编辑
              options: {Array} 选项，当type为单多选、下拉框、级联选择器时可用
            }
     * }
     * */
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    // el-col的跨度
    span: {
      type: Number,
      default: 8
    }
  },
  computed: {
    labelWidth () {
      if (this.$i18n.locale === 'cn') {
        return this.$attrs.labelWidthCn ? `${this.$attrs.labelWidthCn}px` : '140px'
      }
      return this.$attrs.labelWidthEn ? `${this.$attrs.labelWidthEn}px` : '200px'
    }
  },
  data () {
    return {
      formData: {},
      dialogVisible: false,
      selection: []
    }
  },
  methods: {
    setFieldValue () {
      /**
       * 设置字段值
       */
      this.fields.forEach(category => {
        category.fields.forEach(field => {
          if (field.fieldType === 'table') {
            // 字段类型为表格，则值为数组类型
            this.$set(this.formData, field.fieldName, field.default || [])
            if (!this.formData[field.fieldName].length) {
              // 默认值为空数组，则添加一组数据
              const fieldItem = {}
              const fields = field.fields || []
              fields.forEach(item => {
                fieldItem[item.fieldName] = undefined
              })
              this.formData[field.fieldName].push(fieldItem)
            }
          } else {
            // 没有设置默认值，则值为undefined
            this.$set(this.formData, field.fieldName, field.default)
          }
        })
      })
    },
    getRules (item) {
      /**
       * 获取校验规则
       */
      const originalRules = item.rules
      if (originalRules && Array.isArray(originalRules)) {
        return originalRules
      }
      return [{ required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }]
    },
    addTableRow (tableData, field) {
      /**
       * 给表格添加一行数据
       * @param {Array} tableData 表格数据
       * @param {Object} field 表格总体字段数据
       */
      const rowData = {}
      const fields = field.fields || []
      fields.forEach(item => {
        rowData[item.fieldName] = undefined
      })
      tableData.push(rowData)
    },
    deleteTableRow (tableData, fieldName) {
      /**
       * 给表格删除一行数据
       */
      const indexList = []
      // 找出需要删除的行的索引
      tableData.forEach((item, index) => {
        if (this.$refs[fieldName][0].selection.includes(item)) {
          indexList.push(index)
        }
      })
      // 依次删除
      indexList.forEach((item, index) => {
        tableData.splice(item - index, 1)
      })
    },
    getSummaries (fields) {
      /**
       * 合并行
       */
      return (param) => {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = this.$t('totalNum_2')
            return
          }
          if (fields.includes(column.property)) {
            // 合并需要合并的列字段
            const values = data.map(item => Number(item[column.property]))
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
            } else {
              sums[index] = 'N/A'
            }
          } else {
            sums[index] = ''
          }
        })
        return sums
      }
    },
    verifyFormData () {
      /**
       * 供父组件校验数据并获取formData
       * @return {Promise}
       */
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid, object) => {
          if (valid) {
            resolve(this.formData)
          } else {
            this.$message.error(this.$t('checkTips'))
          }
        })
      })
    },
    onSelectChange (value, field) {
      /**
       * 监听选择器选择,如果有gangedEvent，则抛出事件
       * @param {Object} field 字段配置数据
       */
      if (field.gangedEvent) {
        this.$emit(field.gangedEvent, value)
      }
    },
    onInput (value, field) {
      /**
       * 监听选择器选择,如果有gangedEvent，则抛出事件
       * @param {Object} field 字段配置数据
       */
      if (field.gangedEvent) {
        this.$emit(field.gangedEvent, value)
      }
    },
    onRowClick (row, field) {
      /**
       * 监听行点击,如果有gangedEvent，则抛出事件
       * @param {Object} field 字段配置数据
       */
      if (field.gangedEvent) {
        this.$emit(field.gangedEvent, row)
      }
    }
  },
  watch: {
    fields: {
      handler (newValue) {
        this.setFieldValue()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";

.usd-input.el-input--prefix /deep/ {
  .el-input__inner {
    padding-left: 35px;
  }

  .usd-prefix {
    height: 95%;
    display: flex;
    align-items: center;
  }
}

.el-input /deep/ {
  .el-input-group__append, .el-input-group__prepend {
    background-color: #f2f6fc;
    border: 0;
    padding: 0 10px;
    border-radius: 0;
    box-shadow: -2px 0 2px #eef0f3;
    color: #C0C4CC;
    font-weight: 600;
  }

  .el-input-group__prepend {
    background-color: #f9f9f9;
    box-shadow: 2px 0 2px #f1f1f1;
  }
}
</style>
