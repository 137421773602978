// 自定义聚焦指令

const focus = {
  inserted: (el) => {
    const input = el.querySelector('input') || el
    input.focus()
  }
}
// 自定义dialog切换全屏和非全屏指令
const fullscreen = {
  inserted: (el, binding, vnode) => {
    // 添加过渡动画
    const elDialog = el.querySelector('.el-dialog')
    elDialog.style.transition = 'all .3s'
    const closeElement = el.querySelector('.el-dialog__headerbtn')
    // 创建切换图标按钮，并设置style
    const fullscreenElement = document.createElement('i')
    fullscreenElement.classList.add('el-icon-full-screen')
    fullscreenElement.style.cssText = `
      position:absolute;
      top:21px;
      fontSize:14px;
      right:50px;
      cursor:pointer
     `
    // 设置鼠标进入和退出的字体颜色
    fullscreenElement.onmouseenter = e => {
      e.target.style.color = vnode.context.$store.state.currentTheme.primaryColor
    }
    fullscreenElement.onmouseleave = e => {
      e.target.style.color = '#909399'
    }
    fullscreenElement.onclick = e => {
      const dialogElement = el.querySelector('.el-dialog[role=dialog]')
      const dialogBody = dialogElement.querySelector('.el-dialog__body')
      dialogBody.style.overflowY = 'auto'
      // 获取dialog底部高度，以便计算出dialog-body高度
      const dialogFoot = dialogElement.querySelector('.el-dialog__footer')
      const footHeight = dialogFoot ? dialogFoot.clientHeight : 0
      const classList = dialogElement.classList
      if (classList.contains('is-fullscreen')) {
        // 如果是在全屏模式下，取消全屏
        classList.remove('is-fullscreen')
        // window.dialog.width和window.dialog.marginTop为缓存的非全屏窗口宽度及距离顶部高度
        dialogElement.style.width = dialogElement._dialog.width
        dialogElement.style.marginTop = dialogElement._dialog.marginTop
        const top = dialogElement._dialog.marginTop.match(/\d+/)[0]
        const height = (100 - top * 2).toFixed(0)
        dialogBody.style.height = `calc(${height}vh - ${54 + footHeight}px)`
      } else {
        // 缓存dialog的marginTop和width，以便非全屏时恢复
        dialogElement._dialog = {
          marginTop: dialogElement.style.marginTop,
          width: dialogElement.style.width
        }
        // 54px是dialog顶部标题栏的默认height
        dialogBody.style.boxSizing = 'border-box'
        dialogBody.style.height = `calc(100vh - ${54 + footHeight}px)`
        classList.add('is-fullscreen')
        dialogElement.style.width = '100%'
        dialogElement.style.marginTop = 0
      }
    }
    // 最后在关闭按钮在前插入全屏按钮dom
    closeElement.parentElement.insertBefore(fullscreenElement, closeElement)
  }
}

// 自定义去除element-ui表格边框的指令
const noBorder = {
  inserted: (el) => {
    // 创建css样式类
    const style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = '.el-no-border-table th,' +
      '.el-no-border-table td ' +
      '{ border: none !important; }' +
      '.el-no-border-table > .el-table::before { height: 0 }' +
      '.el-no-border-table .el-table__fixed-right::before { height: 0 }' +
      '.el-no-border-table .el-table__fixed-left::before { height: 0 }'
    document.querySelector('head').appendChild(style)
    const parentElement = el.parentElement
    parentElement.classList.add('el-no-border-table')
  },
  componentUpdated (el) {
    // 空内容居中显示
    const emptyBlock = el.querySelector('.el-table__empty-block')
    if (emptyBlock) {
      emptyBlock.style.width = 'auto'
    }
  }
}

// 抽屉拖动指令
const drag = {
  inserted: (el, binding, vnode) => {
    el.setAttribute('draggable', 'false')
    const elDrawerBodyElement = el.querySelector('.el-drawer')
    const i = document.createElement('i')
    i.classList.add('iconfont')
    i.classList.add('icon-pause')
    i.style.cssText = `
      position:absolute;
      top:50%;left:8px;
      color:'#909399';
      transform:translateY(-50%);
      cursor:e-resize
    `
    const _this = vnode.context
    _this.customIelement = i
    i.onmousedown = (e) => {
      _this.customDragStartClientX = e.clientX
      _this.customDrag825 = true
    }
    el.onmouseup = () => {
      _this.customDrag825 = false
    }
    el.onmousemove = (e) => {
      requestAnimationFrame(() => {
        if (_this.customDrag825) {
          const offset = _this.customDragStartClientX - e.clientX
          const offsetWidth = elDrawerBodyElement.offsetWidth + offset
          elDrawerBodyElement.style.width = `${offsetWidth}px`
          _this.customDragStartClientX = e.clientX
        }
      })
    }
    elDrawerBodyElement.appendChild(i)
  },
  unbind: (el, binding, vnode) => {
    // 移除事件监听
    const _this = vnode.context
    el.onmouseup = null
    el.onmousemove = null
    _this.customIelement.onmousedown = null
  }
}

// 禁止编辑指令
const prohibitEdit = {
  inserted: (el, binding) => {
    // globalDisabled全局禁用，itemDisabled当前字段禁用
    const { globalDisabled, itemDisabled } = binding.value
    if (!globalDisabled && itemDisabled) {
      el.style.posiiton = 'relative'
      const maskElement = document.createElement('div')
      maskElement.style.cssText = `
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #EBEEF540;
        display: flex;
        justify-content: center;
        align-items: center;
      `
      const i = document.createElement('i')
      i.classList.add('iconfont')
      i.classList.add('icon-stop')
      i.style.cssText = `
        color: #F56C6C;
        font-weight: 800;
        transform:rotate(90deg)
      `
      maskElement.appendChild(i)
      el.appendChild(maskElement)
    }
  }
}

const dialogDrag = {
  bind: (el, binding) => {
    const dialogHeaderEl = el.querySelector('.el-dialog__header')
    const dragDom = el.querySelector('.el-dialog')
    dialogHeaderEl.style.cursor = 'move'
    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      // const top = dialogHeaderEl.getBoundingClientRect().top
      // const left = dialogHeaderEl.getBoundingClientRect().left
      let disX = 0
      let disY = 0
      document.onmousemove = function (e) {
        disX += e.movementX
        disY += e.movementY
        requestAnimationFrame(() => {
          dragDom.style.left = `${disX}px`
          dragDom.style.top = `${disY}px`
        })
      }
      document.onmouseup = function (e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}

// 权限指令
const permission = {
  checkPermission (el, binding, vnode) {
    const { value } = binding
    const functions = vnode.context.$route.meta.functions || []
    const hasPermission = functions.find(item => item.code === value)
    if (!hasPermission && value !== 'super_admin') {
      // 没有包括功能，则移除子元素
      const { parentElement } = el
      parentElement && parentElement.removeChild(el)
    }
  },
  componentUpdated (el, binding, vnode) {
    permission.checkPermission(el, binding, vnode)
  },
  inserted (el, binding, vnode) {
    permission.checkPermission(el, binding, vnode)
  }
}

const uppercase = {
  bind (el, binding, vnode) {
    if (binding.arg) {
      el.addEventListener('input', (e) => {
        e.target.value = e.target.value.toUpperCase()
        vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
      })
    }
  },
  unbind: () => {}
}

export default {
  drag,
  focus,
  fullscreen,
  permission,
  'no-border': noBorder,
  'prohibit-edit': prohibitEdit,
  'dialog-drag': dialogDrag,
  uppercase
}
