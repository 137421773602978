<template>
  <div class="phone-input">
    <el-select
      v-model="phoneCode"
      :disabled="disabled"
      filterable
      :size="size">
      <el-option
        v-for="(item,index) in phoneCodeList"
        :key="index"
        :label="$i18n.locale==='cn'?item.countryZh:item.countryEn"
        :value="item.code">
        <span style="float: left; font-size: 13px">
          {{ $i18n.locale === 'cn' ? item.countryZh : item.countryEn }}
        </span>
        <span style="float: right; color: #8492a6; font-size: 12px">
          +{{ item.code }}
        </span>
      </el-option>
    </el-select>
    <el-input
      v-model="phoneNoCode"
      :disabled="disabled"
      :size="size">
    </el-input>
  </div>
</template>

<script>
import codes from './phone-international-code'

export default {
  name: 'index',
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    size: {
      default: 'large'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const phoneCodeList = codes.map(item => ({
      countryZh: item.name,
      countryEn: item.en,
      code: item.tel
    }))
    return {
      phoneCodeList,
      phoneCode: '65',
      phoneNoCode: ''
    }
  },
  watch: {
    value: {
      handler (newValue) {
        if (newValue) {
          try {
            const phoneSplit = newValue.split('-')
            this.phoneCode = phoneSplit[0].replace('+', '')
            this.phoneNoCode = phoneSplit[1]
          } catch (e) {
          }
        }
      },
      immediate: true
    },
    phoneCode (newValue) {
      if (newValue && this.phoneNoCode) {
        this.$emit('change', `+${newValue}-${this.phoneNoCode}`)
      }
    },
    phoneNoCode (newValue) {
      const phone = newValue ? `+${this.phoneCode}-${newValue}` : ''
      this.$emit('change', phone)
    }
  }
}
</script>

<style lang="scss" scoped>
.phone-input {
  display: flex;
  /deep/ .el-select {
    min-width: 100px !important;
    input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0;
    }
  }
  /deep/ > .el-input {
    input {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  /* for small size form*/
  /deep/ .el-input__inner {
    height: 32px ;
  }
}
</style>
